// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //production: true, //if ng build --prod
  msalConfig: {
    clientId: 'a064feea-bec0-4a89-8fff-688eaef5b511',
    tenantId: '5b973f99-77df-4beb-b27d-aa0c70b8482c',
    //redirectUri: "https://sphereiotdev.sbp.eyclienthub.com/",
    //redirectUri: "http://localhost:4200/",
    redirectUri: "https://sphereiotdev.sbp.eyclienthub.com/",
    consentScopes: ['user.read', 'openid', 'profile'
    ]
  },
  signalRApiUrl: "https://usediac01hfapp01.azurewebsites.net/",
  apiUrl: 'https://sphereiotdev.sbp.eyclienthub.com/',
  // Modify the ADT URL
  digitalTwinUrl: "https://explorer.digitaltwins.azure.net/?tid=5b973f99-77df-4beb-b27d-aa0c70b8482c&eid=USEDIAC01HADT01.api.eus.digitaltwins.azure.net",
  microservicesBaseUrl: "https://sphereiot-dev-fa.azurewebsites.net/api/device/",
  operationAutoRefreshPeriodInSeconds: 5,
  tsiRealTimeFeedAutoRefreshPeriodInSeconds: 10,
  serviceTicketsAutoRefreshPeriodInSeconds: 15,
  azureMapAutoRefreshPeriodInSeconds: 10,
  evOperationAutoRefreshPeriodInSeconds: 10,
  opcSimulatorStatusCheckPeriodInSeconds: 10,
  appInsights: {
    instrumentationKey:'40a86a03-9fd7-47ed-915d-82a5daa6f94b'
  },
  mqtt: {
    port: 8084,
    host: '40.76.159.180',
    path: '/mqtt',
    clientId: 'iotsphere_ui',
    protocol: 'wss',
  },

};
