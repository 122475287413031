import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrls: ['./dynamic-table.component.scss'],
})
export class DynamicTableComponent implements OnInit {
  @Input() columnsToDisplay;
  dataSource: MatTableDataSource<any>;
  @Input() totalLength;
  readonly NO_DATA_MSG = 'No Data Found.';
  @Output() pageEvent = new EventEmitter<any>();
  @Output() rowClickEvent = new EventEmitter();
  @Input() set tableData(value: any) {
    this._tableData = value;
    if (value) {
      this.dataSource = new MatTableDataSource(this.tableData);
    }
  }
  className = { Approved: 'green', Rejected: 'red', Pending: 'grey' };
  get tableData(): any {
    return this._tableData;
  }
  private _tableData: any;

  constructor() {}

  ngOnInit(): void {}
  getColumnProps() {
    return this.columnsToDisplay.map((col: { key: any }) => col.key);
  }
  getClass(value, element) {
    return value ? this.className[element[value]] : null;
  }
  handlePageEvent(event) {
    this.pageEvent.emit({ page: event });
  }
  sortData(event) {
    this.pageEvent.emit({ sort: event });
  }

  onRowClick(data, page) {
    this.rowClickEvent.emit({ data: data, page: page });
  }
}
