import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ToasterWrapperService {
     successToasterConfig = {
        timeOut: 5000,
        extendedTimeOut: 5000,
      };
       warningToasterConfig = {
        timeOut: 5000,
        extendedTimeOut: 5000,
      };
       errorToasterConfig = {
        timeOut: 5000,
        extendedTimeOut: 5000
      };
       infoToasterConfig = {
        timeOut: 5000,
        extendedTimeOut: 5000,
      };
  private toasterRef = [];

  constructor(private toaster: ToastrService, private router: Router) {
    // clear toast message on route change
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        // clear toast
        this.toasterRef.forEach((ref) => {
          this.toaster.clear(ref.toastId);
        });
      }
    });
  }

  success(message: string, title?: string) {
    this.toaster.success(message, title, this.successToasterConfig);
  }

  error(message: string, title?: string) {
    this.toasterRef.push(
      this.toaster.error(message, title, this.errorToasterConfig)
    );
  }

  // clear() {
  //   this.toaster.clear();
  // }

  warning(message: string, title?: string) {
    this.toasterRef.push(
      this.toaster.warning(message, title, this.warningToasterConfig)
    );
  }

  info(message: string, title?: string) {
    this.toasterRef.push(
      this.toaster.info(message, title, this.infoToasterConfig)
    );
  }
}

