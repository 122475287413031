import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { OptionBase } from 'src/app/models/option-base';

@Injectable({
  providedIn: 'root'
})
export class DynamicFormService {
  constructor() { }
regex : any ;

  toFormGroup(options: OptionBase<string>[]) {
    const group: any = {};

    options.forEach(option => {
      option.required = true;

      if (option.uiDataType==='integer'){
        this.regex= (/^(0|-*[1-9]+[0-9]*)$/);
      }
      else if(option.uiDataType==='float'){
        this.regex= (/^[-+]?[0-9]+\.[0-9]+$/ );
      }
      else if(option.uiDataType==='long'){
        this.regex= (/^(0|-*[1-9]+[0-9]*)$/ )  ;
      }
      else if(option.uiDataType==='double'){
        this.regex= (/^[-+]?[0-9]+\.[0-9]+$/ );


      }
      else {
        this.regex= null;
      }



      // Add function , check type, get regex for type,. return regex , if regex not null, set validators,

   console.log("[option.propertyName", option.propertyName)



      group[option.propertyName] = option.required ? new FormControl(option.value || '', [Validators.required,Validators.pattern(this.regex) ]  )
        : new FormControl(option.value || '');

    });
    return new FormGroup(group);
  }
  ValidatorsFunction(option){
    console.log("Option1",option.uiDataType )

  }
}
