import { Injectable } from '@angular/core';
import { CATEGORY_DATA } from 'src/app/constants/category-data';

@Injectable({
    providedIn: 'root'
})

export class UtilsService {
    private colorsArray = ['#C981B2', '#8CEBAD', '#FF9A91', '#FFB46A', '#93F0E6', '#87D3F2', '#9C82D4']
    private _legendsData = [];
    private _colorsForNodes: Set<any> = new Set();
    private _category: any;
    private _menuData: any;

    constructor() { }

    get category() {
        return this._category ?? CATEGORY_DATA[0];
    }

    set category(data: any) {
        this._category = data;
    }

    get menuData() {
        return this._menuData;
    }

    set menuData(data: any) {
        this._menuData = data;
    }

    get colorsForNodes() {
        return this._colorsForNodes;
    }

    set colorsForNodes(data: any) {
        this._colorsForNodes = data;
    }

    get legendsData() {
        return this._legendsData;
    }

    set legendsData(data: any) {
        this._legendsData = data;
    }

    getRoleId(): number {
        const role = localStorage.getItem('role');
        switch (role?.toLowerCase()) {
            case 'admin': return 3;
            case 'vp of operations': return 1;
            case 'plant operator': return 2;
        }
    }

    getCategoryFromCode(code): any {
        const categories = CATEGORY_DATA;
        const selectedCategory = categories.filter(category => {
            return code === category.code;
        });
        return selectedCategory[0];
    }

    /**
   * Function to return unique items based on key
   * @returns an array of unique keys
   */
    getUniqueItems(items: Array<any>, key: string): Array<string> {
        if (items?.length && key) {
            const uniqueItems = [...new Set(items?.map(item => item[key]))];
            return uniqueItems;
        }
        else
            return [];
    }

    /**
     * Function to genrate Random colors
     * @returns random color
     */

    // generates any color, light or dark
    // getRandomColor(): string {
    //     var letters = '0123456789ABCDEF';
    //     var color = '#';
    //     for (var i = 0; i < 6; i++) {
    //         color += letters[Math.floor(Math.random() * 16)];
    //     }
    //     return color;
    // }

    //generates only light colors
    getRandomColor() {
        const color = "hsl(" + Math.random() * 360 + ", 100%, 75%)";
        return color;
    }

    /**
    * Function to create a set of unique Colors based on the array of strings provided
    * @param uniqueNodes list of unique string data
    */
    getColorsForNodes(uniqueNodes: Array<string>): void {
        let index = 0;
        uniqueNodes?.forEach(node => {
            if (!this._colorsForNodes?.[node]) {
                this._colorsForNodes[node] = index < this.colorsArray.length ? this.colorsArray[index] : this.getRandomColor();
                index++;
            }
        })
        console.log(this._colorsForNodes);
    }

    getLegendsData(items: Array<string>) {
        this._legendsData = [];
        items.forEach(item => {
            this._legendsData.push({
                name: item,
                color: this._colorsForNodes[item]
            })
        });
    }
}
