<div class="">
  <div class="table-responsive">
    <table
      mat-table
      [dataSource]="dataSource"
      multiTemplateDataRows
      matTableExporter
      matSort
      (matSortChange)="sortData($event)"
      class="mat-elevation-z8 h-100"
      *ngIf="tableData?.length; else spinner"
    >
      <ng-container
        [matColumnDef]="column.key"
        *ngFor="let column of columnsToDisplay; let colIndex = index"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          disableClear
          [mat-sort-header]="column"
          [disabled]="column?.disabled"
          class="px-2"
        >
          <ng-container *ngIf="column.key === 'select'">
            <mat-checkbox
              (change)="$event ? toggleAllRows() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
              [aria-label]="checkboxLabel()"
              [disabled]=""
              class="mr-2"
            >
            </mat-checkbox>
          </ng-container>
          {{ column.name }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = dataIndex"
          class="px-2"
          [ngClass]="(i + 1) % 2 !== 0 ? 'bg1' : 'bg2'"
        >
          <ng-container *ngIf="column.key === 'select'">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(element) : null"
              [checked]="selection.isSelected(element)"
              [aria-label]="checkboxLabel(element)"
              class="mr-2"
            >
            </mat-checkbox>
          </ng-container>

          <span
            class="{{ column?.class }}"
            [ngClass]="getClass(column.classProperty, element)"
          >
            <span
              *ngIf="column.isLink"
              class="ey-cp ey-underline"
              (click)="onRowClick(element, page)"
              >{{ element[column.key] }}</span
            >
            <span
              *ngIf="!column.isLink"
              [ngClass]="{ status: column?.backgroundColor }"
              [style.background-color]="
                 column.backgroundColor?.[element?.[column.key]] || ''
              "
            >
              {{ element[column.key] }}</span
            >
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="getColumnProps()"></tr>
      <tr
        #tableRow
        mat-row
        *matRowDef="let element; columns: getColumnProps()"
        class="element-row"
      ></tr>
    </table>
  </div>
  <mat-paginator
    *ngIf="totalLength"
    #paginator
    [pageSizeOptions]="[10, 20, 30]"
    [length]="totalLength"
    [pageSize]="pageSize"
    [pageIndex]="pageIndex"
    (page)="handlePageEvent($event)"
    [showFirstLastButtons]="true"
  >
  </mat-paginator>

  <ng-template #spinner>
    <div class="text-center" *ngIf="!(errorMessage || tableData?.length === 0)">
      <img
        src="assets/images/spinner.gif"
        alt="Inprogress"
        class="inprogres-image"
      />
    </div>
    <div *ngIf="errorMessage || tableData?.length === 0" class="text-center">
      {{ errorMessage || NO_DATA_MSG }}
    </div>
  </ng-template>
</div>
