export const MENU_DATA_DETAILS = {
  ASSET_MONITORING: {
    id: 5,
    label: 'Asset Monitoring',
    routerLink: null,
    icon: 'precision_manufacturing',
    children: [
      {
        id: 1,
        label: 'Vitals',
        routerLink: '/home/asset-monitoring/vitals',
        children: [],
      },
      {
        id: 2,
        label: 'KPI',
        children: [
          {
            id: 1,
            label: 'BenchMill',
            icon: '',
            routerLink: '/home/asset-monitoring/kpi/benchmill',
            children: [],
          },
          {
            id: 2,
            label: 'Laser Engraver',
            icon: '',
            routerLink: '/home/asset-monitoring/kpi/laser-engraver',
            children: [],
          },
        ],
      },
    ],
  },
  PLANT_VIEW: {
    id: 6,
    label: 'Plant View',
    routerLink: null,
    icon: 'apartment',
    children: [
      {
        id: 1,
        label: 'Quality Metrics',
        routerLink: '/home/plant-view/quality-metrics',
        dynamicUrl:
          'https://euwdsrg03rwap10.azurewebsites.net/d/ik8f8RnVq/quality-and-financial-metrics?orgId=1',
        children: [],
      },
      {
        id: 2,
        label: 'Downtime',
        routerLink: '/home/plant-view/downtime',
        dynamicUrl:
          'https://euwdsrg03rwap10.azurewebsites.net/d/ik8f8RnVd/downtime?orgId=1',
        children: [],
      },
      {
        id: 3,
        label: 'Floor 1',
        isDigitalTwinUrl: true,
        routerLink: '/home/plant-view/floor-1',
        dynamicUrl:
          'https://explorer.digitaltwins.azure.net/3DScenes/?sceneId=0a7f170139854838808bccd881b124fd&selectedLayerIds=scene-layer-dropdown-unlayered-behaviors%2Cd51431cc8a8105dd686f5b3a120bcc97%2C994320f0e9eb980a8a50660aaac792f9&mode=Viewer&adtUrl=https%3A%2F%2Fusediac01hadt01.api.eus.digitaltwins.azure.net&storageUrl=https%3A%2F%2Fusediac01hsta01.blob.core.windows.net%2Fdigitaltwin3dscene&tid=5b973f99-77df-4beb-b27d-aa0c70b8482c',
        children: [],
      },
    ],
  },
  ASSET_MANAGEMENT: {
    id: 7,
    label: 'Asset Management',
    icon: 'important_devices',
    routerLink: null,
    children: [
      {
        id: 1,
        label: 'Summary',
        routerLink: '/home/asset-management',
        children: [],
      },
      {
        id: 2,
        label: 'Bulk Upload',
        routerLink: '/home/asset-management/bulk-upload',
        children: [],
      }
    ],
  },
  DEVICE_MANAGEMENT: {
    id: 1,
    label: 'Device Management',
    routerLink: null,
    icon: 'devices',
    children: [
      {
        id: 1,
        label: 'Summary',
        routerLink: '/home/device-management',
        children: [],
      },
      {
        id: 2,
        label: 'Device List',
        routerLink: '/home/device-management/device-list',
        children: [],
      },
      // TODO: need to implement Device Hierarchy module
      // commented to hide menu during demo until the actual module gets developed
      // {
      //   id: 2,
      //   label: 'Device Hierarchy',
      //   icon: 'account_tree',
      //   routerLink: '/home/device-management/device-hierarchy'
      // },
      {
        id: 3,
        label: 'FOTA',
        routerLink: '/home/device-management/firmware-list',
        children: [],
      },
      {
        id: 4,
        label: 'FOTA 2.0 Preview',
        routerLink: '/home/device-management/new-firmware-list',
        children: []
      }
    ],
  },
  DEVICE_SIMULATOR: {
    id: 2,
    label: 'Device Simulator',
    icon: 'grain',
    routerLink: '/home/opc-simulator',
    children: [],
  },
  USER_MANAGEMENT: {
    id: 3,
    label: 'User Management',
    icon: 'manage_accounts',
    routerLink: '/home/user-mgmt',
    children: [],
  },
  USE_CASES: {
    id: 4,
    label: 'Use Cases',
    icon: 'domain_add',
    routerLink: null,
    children: [
      {
        id: 1,
        label: 'Smart Warehouse',
        routerLink: '/home/smart-warehouse-frame',
        children: [],
      },
      {
        id: 2,
        label: 'Asset Tracking',
        routerLink: '/home/asset-tracking',
        children: [],
      },
      {
        id: 3,
        label: 'Hybrid Power Plant',
        routerLink: '/home/hybrid-power-plant',
        children: [],
      },
      {
        id: 4,
        label: 'Cutting Blade',
        routerLink: '/home/cutting-blade',
        children: [],
      },
      {
        id: 5,
        label: 'Package Monitoring',
        routerLink: '/home',
        children: [],
      },
    ],
  },
  INDOOR_MAP: {
    id: 5,
    label: 'Indoor Map',
    icon: 'map',
    routerLink: '/home/indoor-map',
    children: [],
  },
};
