export const CATEGORY_DATA = [
    {
        id: 1,
        code: 'MANUFACTURING',
        label: 'Industry 4.0',
        title: 'IoTSphere Manufacturing Systems',
        class: 'manufacturing',
        src:'../../../../../assets/images/main/manufacturing.jpg',
        description:'The IoT offers the potential to revolutionize the manufacturing industry by optimizing production processes, reducing downtime, improving workplace safety and enabling predictive maintenance.',
        status:'ready',
        userRoles: [1, 2, 3]
    }, {
        id: 2,
        code: 'POWER',
        label: 'Power and Utilities',
        title: 'IoTSphere',
        class: 'power',
        src:'../../../../../assets/images/main/power-and-utilities.jpg',
        description:'Through the use of smart sensors, IoT also enables businesses and organizations to automatically switch energy consumption on or off based on the occupancy of a room or building, preserving energy and lowering utility bills.',
        status:'ready',
        userRoles: [1]
    }, {
        id: 3,
        code: 'SUPPLY_CHAIN',
        label: 'Supply Chain',
        title: 'IoTSphere',
        class: 'supply',
        src:'../../../../../assets/images/main/supply-chain.jpg',
        description:'Leveraging IoT makes it much easier to locate your goods, track their movement, identify where and when they are delayed in transit and plan alternative routes, monitor their storage conditions (temperature, humidity etc.), and estimate when they will arrive at a specific location.',
        status:'ready',
        userRoles: [1]
    }, {
        id: 4,
        code: 'HEALTH_CARE',
        label: 'Patient Care',
        title: 'IoTSphere',
        class: 'health',
        src:'../../../../../assets/images/main/health.jpg',
        description:'IoT for Hospitals - Apart from monitoring patients health, there are many other areas where IoT devices are very useful in hospitals. IoT devices tagged with sensors are used for tracking real time location of medical equipment like wheelchairs, defibrillators, nebulizers, oxygen pumps and other monitoring equipment.',
        status:'in-progress',
        userRoles: [1,2,3]
    },
];