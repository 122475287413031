import { Injectable, ErrorHandler } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

@Injectable()
export class ApplicationInsightsService {  
  private appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: environment.appInsights.instrumentationKey,
    },
  });

  constructor(private router: Router) {
    this.appInsights.loadAppInsights();
  }

  // expose methods that can be used in components and services
  trackEvent(name: string): void {
    this.appInsights.trackEvent({ name });
  }

  trackTrace(message: string): void {
    this.appInsights.trackTrace({ message });
  }
}
