import { MENU_DATA_DETAILS } from "./menu-constant-data";

// Priveleges - 1 VP of Operations, 2 Plant Operator, 3 Admin
export const MENU_DATA = {
    MANUFACTURING: {
        1: [
            MENU_DATA_DETAILS.PLANT_VIEW,
            MENU_DATA_DETAILS.ASSET_MONITORING,
            MENU_DATA_DETAILS.ASSET_MANAGEMENT,
            {
                ...MENU_DATA_DETAILS.USE_CASES,
                children: [
                    MENU_DATA_DETAILS.USE_CASES.children[3]
                ]
            }
        ],
        2: [
            MENU_DATA_DETAILS.PLANT_VIEW,
            MENU_DATA_DETAILS.ASSET_MONITORING,
            MENU_DATA_DETAILS.ASSET_MANAGEMENT,
            MENU_DATA_DETAILS.DEVICE_MANAGEMENT,
            MENU_DATA_DETAILS.DEVICE_SIMULATOR,
            {
                ...MENU_DATA_DETAILS.USE_CASES,
                children: [
                    MENU_DATA_DETAILS.USE_CASES.children[3]
                ]
            },
            MENU_DATA_DETAILS.INDOOR_MAP,
        ],
        3: [
            MENU_DATA_DETAILS.PLANT_VIEW,
            MENU_DATA_DETAILS.ASSET_MONITORING,
            MENU_DATA_DETAILS.ASSET_MANAGEMENT,
            MENU_DATA_DETAILS.DEVICE_MANAGEMENT,
            MENU_DATA_DETAILS.DEVICE_SIMULATOR,
            MENU_DATA_DETAILS.USER_MANAGEMENT,
            {
                ...MENU_DATA_DETAILS.USE_CASES,
                children: [
                    MENU_DATA_DETAILS.USE_CASES.children[3]
                ]
            },
            MENU_DATA_DETAILS.INDOOR_MAP,
        ]
    },
    SUPPLY_CHAIN: {
        3: [
            MENU_DATA_DETAILS.ASSET_MANAGEMENT,
            MENU_DATA_DETAILS.DEVICE_MANAGEMENT,
            MENU_DATA_DETAILS.DEVICE_SIMULATOR,
            MENU_DATA_DETAILS.USER_MANAGEMENT,
            {
                ...MENU_DATA_DETAILS.USE_CASES,
                children: [
                    MENU_DATA_DETAILS.USE_CASES.children[0],
                    MENU_DATA_DETAILS.USE_CASES.children[1]
                ]
            },
            MENU_DATA_DETAILS.INDOOR_MAP,
        ]
    },
    POWER: {
        3: [
            MENU_DATA_DETAILS.ASSET_MANAGEMENT,
            MENU_DATA_DETAILS.DEVICE_MANAGEMENT,
            MENU_DATA_DETAILS.DEVICE_SIMULATOR,
            MENU_DATA_DETAILS.USER_MANAGEMENT,
            {
                ...MENU_DATA_DETAILS.USE_CASES,
                children: [
                    MENU_DATA_DETAILS.USE_CASES.children[2]
                ]
            },
            MENU_DATA_DETAILS.INDOOR_MAP,
        ]
    },
    HEALTH_CARE: {
        1: [
            {
                ...MENU_DATA_DETAILS.PLANT_VIEW,
                children: [
                    MENU_DATA_DETAILS.PLANT_VIEW.children[0]
                ]
            },
            MENU_DATA_DETAILS.ASSET_MONITORING,
            MENU_DATA_DETAILS.ASSET_MANAGEMENT,
            MENU_DATA_DETAILS.DEVICE_MANAGEMENT,
            MENU_DATA_DETAILS.DEVICE_SIMULATOR,
            MENU_DATA_DETAILS.USER_MANAGEMENT,
            MENU_DATA_DETAILS.USE_CASES
        ],
        2: [
            {
                ...MENU_DATA_DETAILS.PLANT_VIEW,
                children: [
                    MENU_DATA_DETAILS.PLANT_VIEW.children[1]
                ]
            },
            MENU_DATA_DETAILS.ASSET_MONITORING,
            MENU_DATA_DETAILS.ASSET_MANAGEMENT,
            MENU_DATA_DETAILS.DEVICE_MANAGEMENT,
            MENU_DATA_DETAILS.DEVICE_SIMULATOR,
            MENU_DATA_DETAILS.USER_MANAGEMENT,
            MENU_DATA_DETAILS.USE_CASES
        ],
        3: [
            MENU_DATA_DETAILS.PLANT_VIEW,
            MENU_DATA_DETAILS.ASSET_MONITORING,
            MENU_DATA_DETAILS.ASSET_MANAGEMENT,
            MENU_DATA_DETAILS.DEVICE_MANAGEMENT,
            MENU_DATA_DETAILS.DEVICE_SIMULATOR,
            MENU_DATA_DETAILS.USER_MANAGEMENT,
            MENU_DATA_DETAILS.USE_CASES
        ]
    }
}
