<div [formGroup]="form" *ngIf="option" class="ey-w-100">
  <!-- <label [attr.for]="option.propertyLabel">{{option.propertyName}}</label> -->
  <div [ngSwitch]="option.uiFieldType">
    <ng-container *ngSwitchCase="controlTypesEnum.TEXTBOX">
      <div [ngSwitch]="option.uiDataType">
        <ng-container *ngSwitchCase="'date'">
          <mat-form-field appearance="fill" class="ey-w-100">
            <input matInput [matDatepicker]="picker" placeholder="MM/DD/YYYY" disabled
              [formControlName]="option.propertyName">
              <mat-icon class="cursorPointer"   matSuffix mat-icon-button (click)="picker.open()">date_range</mat-icon>
            <mat-hint>Choose a Date for {{option?.propertyLabel}}</mat-hint>
            <mat-datepicker #picker disabled="false"></mat-datepicker>
          </mat-form-field>
        </ng-container>
        <ng-container *ngSwitchCase="'time'">
          <mat-form-field appearance="fill" class="ey-w-100">
            <input matInput disableClick="true" disabled="true" [format]="24" placeholder="HH:MM" [ngxMatTimepicker]="picker"
              [formControlName]="option.propertyName">
            <mat-icon class="cursorPointer" matSuffix mat-icon-button (click)="picker.open()">access_time</mat-icon>
            <mat-hint>Choose a Time for {{option?.propertyLabel}}</mat-hint>
            <ngx-mat-timepicker #picker></ngx-mat-timepicker>
          </mat-form-field>
        </ng-container>
        <ng-container *ngSwitchCase="'dateTime'">
          <mat-form-field appearance="fill" class="ey-w-100">
            <div class="parent">
              <ng-container>
                <input class="dateField" placeholder="MM/DD/YYYY" disabled type="any" matInput
                  [matDatepicker]="pickerD" [formControlName]="option.propertyName">
                <mat-icon class="cursorPointer" matSuffix mat-icon-button (click)="pickerD.open()">date_range</mat-icon>
                <mat-datepicker #pickerD disabled="false"></mat-datepicker>
              </ng-container>
              <ng-container>
                <input class="timeField" disableClick="true" disabled="false" [format]="24" matInput
                  placeholder="HH:MM" [ngxMatTimepicker]="pickerT" [formControlName]="option.propertyName">
                <mat-icon class="cursorPointer" matSuffix mat-icon-button (click)="pickerT.open()">access_time</mat-icon>
                <ngx-mat-timepicker #pickerT></ngx-mat-timepicker>
              </ng-container>
            </div>
            <mat-hint>Choose a Date and Time for {{option?.propertyLabel}}</mat-hint>
          </mat-form-field>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <mat-form-field appearance="fill" class="ey-w-100">
            <mat-label class="dynamic-form-label">{{option?.propertyLabel}}</mat-label>
            <input matInput [formControlName]="option.propertyName" [id]="option.propertyName"
              [type]="option.uiDataType === 'string' ? 'text':'number'">
            <mat-hint class="asset-name-hint" *ngIf="option?.propertyLabel==='Asset Name'">
              <mat-icon class="hint-icon">info</mat-icon>
              <span class="hint-text">Allowed Asset Name format is like testName, test_name</span></mat-hint>
          </mat-form-field>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="controlTypesEnum.RADIO">
      <div class="ey-vc ">
        <div class="ey-fs-20">{{option?.propertyLabel}} :</div>
        <div *ngFor="let opt of option.options" class="ey-vc ey-ml-15">
          <input type="radio" [value]="opt.value" [name]="option.propertyName" [formControlName]="option.propertyName"
            [id]="opt.value" />
          <label class="test" [for]="opt.value">{{opt.value}}</label>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="controlTypesEnum.DROPDOWN">
      <mat-form-field appearance="fill" class="ey-w-100">
        <mat-label class="dynamic-form-label">{{option?.propertyLabel}}</mat-label>
        <mat-select [formControlName]="option.propertyName" [id]="option.propertyName">
          <mat-option>None</mat-option>
          <mat-option *ngFor="let opt of option.options" [value]="opt.value">
            {{opt.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <ng-container *ngSwitchCase="controlTypesEnum.MULTISELECT">
      <mat-form-field appearance="fill" class="ey-w-100">
        <mat-label>{{option?.propertyLabel}}</mat-label>
        <mat-select [formControlName]="option.propertyName" [id]="option.propertyName" [multiple]="true" #multiSelect>
          <mat-option>
            <ngx-mat-select-search [formControl]="searchMultiSelectCtrl" placeholderLabel="search"
              closeIcon="close" noEntriesFoundLabel="No Entries found">
            </ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let opt of multiSelectDataSource" [value]="opt.value">
            {{opt.value}}
          </mat-option>      
        </mat-select>
      </mat-form-field>
    </ng-container> 
    <!--dynamic search select component start -->
    <ng-container *ngSwitchCase="controlTypesEnum.SELECT">
      <app-dynamic-search-select [option]="option" [selectSearchPlaceholder]="'Search device for mapping'" 
      (emitSearchValue)="getSearchedInput($event)" (emitSearchPageChangeEvent)="handleSearchPageChangeEvent($event)"></app-dynamic-search-select>     
    </ng-container>
     <!--dynamic search select component end -->
  </div>
  <div class="errorMessage" *ngIf="isInValid">{{option.propertyLabel}} is required</div>
</div>
