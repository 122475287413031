import { environment } from '@env';

export const API_SUB_URLS = {
  coreAPI: 'iotsphereapi/',
  securedApi: 'securedApi/',
  deviceManagementApi: 'devicemanagementapi/',
  assetManagementAPI: 'assetmanagement/',
  newAssetManagementAPI: 'assetmanagementapi/',
  genericSimulatorAPI:'devicemanagementapi/simulator/',
};

export function getCoreAPIBaseUrl(): string {
  return environment.apiUrl + API_SUB_URLS.coreAPI;
}

function getSecuredAPIUrl(): string {
  return environment.apiUrl + API_SUB_URLS.coreAPI + API_SUB_URLS.securedApi;
}

export function getDeviceManagementUrl(): string {
  return environment.apiUrl + API_SUB_URLS.deviceManagementApi;
}

function assetManagementUrl(): string {
  return (
    environment.apiUrl +
    API_SUB_URLS.deviceManagementApi +
    API_SUB_URLS.assetManagementAPI
  );
}

function newAssetManagementUrl(): string {
  return (environment.apiUrl + API_SUB_URLS.newAssetManagementAPI);
}

function genericSimulatorUrl(): string {
  return (environment.apiUrl + API_SUB_URLS.genericSimulatorAPI);
}

export const API_URLS = {
  keyVaultKeys: getSecuredAPIUrl() + 'keyVaultKeys',
  addAssetEndPoint: getSecuredAPIUrl() + 'addOrUpdateAsset',
  retrieveAssetInfoEndPoint: getSecuredAPIUrl() + 'fetchAssetParams',
  updateAssetInfoEndPoint: getSecuredAPIUrl() + 'updateAssetInfo',
  deleteAssetEndPoint: getSecuredAPIUrl() + 'deleteAsset',
  IoTStartCommandEndPoint:
    getSecuredAPIUrl() + 'opcEdgeCommandServiceStartCall',
  IoTStopCommandEndPoint: getSecuredAPIUrl() + 'opcEdgeCommandServiceStopCall',
  allCriticalEventsEndPoint: getSecuredAPIUrl() + 'getPolarDelightEvents',
  onlineOfflineStatus: getSecuredAPIUrl() + 'getAssetsStatus',
  fetchAllVehicles: getSecuredAPIUrl() + 'fetchAllVehicles',
  fetchDistinctAssets: getSecuredAPIUrl() + 'fetchDistinctAssets',
  startCsvSimulatorEndPoint: getSecuredAPIUrl() + 'startCsvSimulator',
  stopCsvSimulatorEndPoint: getSecuredAPIUrl() + 'stopCsvSimulator',
  isSimulatorStatusEndPoint: getSecuredAPIUrl() + 'isSimulatorsRunning',
  deviceDetailsEndPoint: getDeviceManagementUrl() + 'deviceDetailsEndPoint',
  getDeviceTwinData: getDeviceManagementUrl() + 'GetDeviceTwinData',
  UpdateDeviceTwinData: getDeviceManagementUrl() + 'UpdateDeviceTwinData',
  directMethodCallEndPoint: getDeviceManagementUrl() + 'CallDirectMethod',
  getConnectionHistoryForDevice:
    getDeviceManagementUrl() + 'GetConnectionHistoryForDevice',
  getAllDevicesLocaltion: getDeviceManagementUrl() + 'GetDeviceLatLongList',
  createEnrolment: getDeviceManagementUrl() + 'CreateEnrolment',
  bulkEnrolment: getDeviceManagementUrl() + 'BulkEnrolment',
  getDeviceTypes: getDeviceManagementUrl() + 'GetDeviceTypes',
  getExistingGroupEnrolmentIds:
    getDeviceManagementUrl() + 'GetExistingGroupEnrolmentIds',
  blockOrUnBlockIndividualDevice:
    getDeviceManagementUrl() + 'BlockOrUnBlockIndividualDevice',
  uploadSensorMetadata: getDeviceManagementUrl() + 'UploadSensorMetadata',
  getDeviceDetailList: getDeviceManagementUrl() + 'GetDeviceDetailList',
  blockGroupEnrolledDeviceWithCertificate:
    getDeviceManagementUrl() + 'BlockGroupEnrolledDeviceWithCertificate',
  decommissionDevice: getDeviceManagementUrl() + 'DecommissionDevice',
  createEmptyGroupEnrollmentWithCertificate:
    getDeviceManagementUrl() + 'CreateEmptyGroupEnrollmentWithCertificate',
  getOrphanDeviceCount: getDeviceManagementUrl() + 'GetOrphanDeviceCount',
  getOrphanDeviceList: getDeviceManagementUrl() + 'GetOrphanDeviceList',
  reuploadDecommisionedDevice:
    getDeviceManagementUrl() + 'ReuploadDecommisionedDevice',
  bulkUpdateOrphanDevice: getDeviceManagementUrl() + 'BulkUpdateOrphanDevice',
  bulkUpdateOrphanDeviceFromCSV:
    getDeviceManagementUrl() + 'BulkUpdateOrphanDeviceFromCSV',
  getTotalTelemetryCountForAllDevices:
    getDeviceManagementUrl() + 'GetTotalTelemetryCountForAllDevices',
  getTotalTelemetryCount: getDeviceManagementUrl() + 'GetTotalTelemetryCount',
  getSensorDetails: getDeviceManagementUrl() + 'GetSensorDetails',
  getDeviceModules: getDeviceManagementUrl() + 'GetModules',

  // Firmware
  getAllFirmware: getSecuredAPIUrl() + 'allFirmwares',
  downloadSpecificFirmware: getCoreAPIBaseUrl() + 'download/',
  deleteSpecificFirmware: getCoreAPIBaseUrl() + 'remove/',
  saveFirmware: getSecuredAPIUrl() + 'save',

  // New Firmware
  newFirmwareSave: getDeviceManagementUrl() + 'fotamanagement/AddFirmware',
  getAllNewFirmware:
    getDeviceManagementUrl() + 'fotamanagement/GetAllFirmwareDetails',
  getAllGroupFirmware:
    getDeviceManagementUrl() + 'fotamanagement/AvailableFirmwareUpdates',
  scheduleDeployment:
    getDeviceManagementUrl() + 'fotamanagement/ScheduleDeviceUpdate',
  scheduledJobs: getDeviceManagementUrl() + 'fotamanagement/ScheduledJobs',
  scheduledActiveJobsUrl: `${getSecuredAPIUrl()}getScheduledActiveJobs`,
  scheduledCompletedJobsUrl: `${getSecuredAPIUrl()}getScheduledCompletedJobs`,
  cancelScheduledJobs: `${getSecuredAPIUrl()}cancelScheduledJobs`,
  rollbackCompletedJobs: `${getSecuredAPIUrl()}rollbackCompletedJobs`,
  getFirmwareVersionList:  `${getSecuredAPIUrl()}getFirmwareVersionList`,
  getDeviceStatusCountByType:  `${getSecuredAPIUrl()}getDeviceStatusCountByType`,
  rollbackJobs:  `${getSecuredAPIUrl()}rollbackJobs`,
  getFirmwareDeviceTypeList:  `${getSecuredAPIUrl()}getFirmwareDeviceTypeList`,
  getFirmware:  `${getSecuredAPIUrl()}getFirmware`,
  getDeviceListFilterGroup:  `${getSecuredAPIUrl()}getDeviceListFilterGroup`,

  //asset management
  getAssetTypes: assetManagementUrl() + 'GetAssetTypes',
  createAssetType: assetManagementUrl() + 'ConfigureAssetType',
  getRelationList: assetManagementUrl() + 'RelationsByAssetTypeId',
  getPropertyList: assetManagementUrl() + 'PropertiesByAssetTypeId',
  getAssetByRelationAndAssetType:
    assetManagementUrl() + 'GetAssetByRelationAndAssetTypeId',
  createAsset: assetManagementUrl() + 'CreateTwin',
  getAllAssetTypes: assetManagementUrl() + 'GetAllAssetTypesDetails',
  getAllAssets: assetManagementUrl() + 'GetAllAsset',
  getAllDevicesForAssetMapping: assetManagementUrl() + 'GetAllDevicesForAssetMapping',

  // Asset Management version 2
  getDomainList:newAssetManagementUrl()+'domain/list',
  getProjectList:newAssetManagementUrl()+'Project/list',
  getModelList:newAssetManagementUrl()+'model/list',
  getAssetsList:newAssetManagementUrl()+'assets/list',
  getAssetsForProjectId: newAssetManagementUrl() + 'assets/assetgraph',
  getModelsForProjectId: newAssetManagementUrl() + 'model/modelgraph',
  deleteModelList:newAssetManagementUrl()+'model/',
  deleteProjectList:newAssetManagementUrl()+'Project/',  
  deleteDomainList:newAssetManagementUrl()+'domain/',
  deleteAssetList:newAssetManagementUrl()+'assets/',
  createModel:newAssetManagementUrl()+'model',
  createProject:newAssetManagementUrl()+'Project',
  createDomain:newAssetManagementUrl()+'domain',
  createAssets:newAssetManagementUrl()+'assets',
  updateModel:newAssetManagementUrl()+'model/',
  updateAsset:newAssetManagementUrl()+'assets/',
  updateDomain:newAssetManagementUrl()+'domain/',
  updateProject:newAssetManagementUrl()+'Project/',
  getAssetDetails:newAssetManagementUrl()+'assets/',
  getModelDetails:newAssetManagementUrl()+'model/',
  getDataBasedOnQuery:newAssetManagementUrl() +'query/custom',
  getAllDomainProjectList:newAssetManagementUrl()+'project/list/all',
  getDomainDetailsById:newAssetManagementUrl()+'Domain/',
  getProjectDetailsById:newAssetManagementUrl()+'project/',
  getProjectModelsList:newAssetManagementUrl()+'model/list',
  getMostSearchedQueryList:newAssetManagementUrl()+'query',
  getAssetDetailsById:newAssetManagementUrl()+'assets/',
  getModelDetailsById:newAssetManagementUrl()+'model/',

  // Api for bulk Upload Models and Assets Job
  getUploadedModelJobList:newAssetManagementUrl()+'job/models/Import',
  uploadModelFile:newAssetManagementUrl()+'job/models/import',
  deleteModelJob:newAssetManagementUrl()+'job/models/Import/',
  getUploadedAssetJobList:newAssetManagementUrl()+'job/assets/Import',
  uploadAssetFile:newAssetManagementUrl()+'job/assets/import',
  deleteAssetJob:newAssetManagementUrl()+'job/assets/Import/',

  // Api for generic simulator/custom asset management (MxD asset management)
  getAllAssetList:genericSimulatorUrl()+'GetAllAssetList',
  getGenericAssetTypes:genericSimulatorUrl()+'GetAssetTypes',
  saveAssetDetails:genericSimulatorUrl()+'Save/AssetDetails',
  saveProcessConfiguration:genericSimulatorUrl()+'Save/ProcessConfiguration',
  saveSensorConfiguration:genericSimulatorUrl()+'Save/SensorConfiguration',
  submitAssetCreation:genericSimulatorUrl()+'SubmitAssetCreation',
}
