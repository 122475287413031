<form [formGroup]="form" *ngIf="option" class="ey-w-100">
  <mat-form-field appearance="fill" class="ey-w-100">
    <mat-label>{{option?.propertyLabel}}</mat-label>
    <mat-select [formControlName]="option.propertyName" [id]="option.propertyName">
      <mat-option>
        <ngx-mat-select-search [formControl]="filterFormControl" [placeholderLabel]="selectSearchPlaceholder"
          closeIcon="close" noEntriesFoundLabel="No Entries found">
        </ngx-mat-select-search>
      </mat-option>
      <mat-option *ngFor="let data of dataSource" [value]="data.value">
        {{data.value}}
      </mat-option>
      <mat-paginator *ngIf="isDynamic" [length]="totalCount" [pageSize]="pageSize" [pageIndex]="pageNumber"
        (page)="handlePageChangeEvent($event)" aria-label="Select page">
      </mat-paginator>
    </mat-select>
  </mat-form-field>
</form>