import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { OptionBase } from 'src/app/models/option-base';
import { DynamicFormService } from 'src/app/services/dynamic-form/dynamic-form.service';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
})
export class DynamicFormComponent implements OnInit {
  dynamicFormOptions: OptionBase<string>[] | null = [];
  showErrors: boolean = false;
  @Input() set options(formOptions: OptionBase<string>[] | null) {
    this.showErrors = false;
    this.dynamicFormOptions = formOptions;
    this.form = this.dynamicFormService.toFormGroup(
      this.dynamicFormOptions as OptionBase<string>[]
    );
  }
  @Output() emitFormValue = new EventEmitter<any>();

  form!: FormGroup;
  @Output() emitSearchedDevice = new EventEmitter<string>();
  @Output() emitSearchPageChangedNumber = new EventEmitter<number>();
  constructor(private dynamicFormService: DynamicFormService) {}

  ngOnInit() {}

  onSubmit() {
    if (this.form.valid) {
      this.showErrors = false;
      let formValue = this.form.getRawValue();
      Object.entries(formValue).find(([key, value]) => {
        const isArrayDataType = Array.isArray(value);
        if (isArrayDataType) {
          formValue = { ...formValue, [key]: value.toString() };
        }
      });
      this.emitFormValue.emit(formValue);
    } else {
      this.showErrors = true;
    }
  }

  getSearchedInput(data: string) {
    this.emitSearchedDevice.emit(data);
  }

  handleSearchPageChange(data: number) {
    this.emitSearchPageChangedNumber.emit(data);
  }
}
