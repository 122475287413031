import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import {
  debounceTime,
  distinctUntilChanged,
  startWith,
  takeUntil,
} from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PageEvent } from '@angular/material/paginator';
import { MessageService } from 'src/app/services/message/message.service';
import { AppEventType } from 'src/app/enums/app-event.enum';

@Component({
  selector: 'app-dynamic-search-select',
  templateUrl: './dynamic-search-select.component.html',
  styleUrls: ['./dynamic-search-select.component.scss'],
})
export class DynamicSearchSelectComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() option: any = [];
  @Input() selectSearchPlaceholder!: string;
  @Output() emitSearchValue = new EventEmitter<string>();
  @Output() emitSearchPageChangeEvent = new EventEmitter<number>();
  filterFormControl: FormControl = new FormControl('');
  pageSize: number = 10;
  pageNumber: number = 0;
  totalCount: number = 0;
  isDynamic: boolean = false;
  dataSource: Array<any> = [];
  form: FormGroup;
  protected _onDestroy = new Subject<void>();
  constructor(
    private formGroupDirective: FormGroupDirective,
    private messageService: MessageService
  ) {
    this.form = formGroupDirective.control;
    this.messageService
      .on(AppEventType.UPDATE_SELECT_DYNAMIC_OPTIONS)
      .subscribe((data: any) => {
        if (this.isDynamic) {
          this.dataSource = data?.payload?.options;
          this.totalCount = data?.payload?.total;
        }
      });
  }

  ngOnInit(): void {
    this.listenToFilterFormControlChanges();
  }

  private listenToFilterFormControlChanges(): void {
    this.filterFormControl.valueChanges
      .pipe(
        startWith(''),
        takeUntil(this._onDestroy),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe((value: string) => {
        if (value) {
          this.pageNumber = 0;
        }
        this.isDynamic
          ? this.emitSearchValue.emit(value)
          : this.filterStaticList();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('option') && this.option) {
      let checkValueNone: boolean;
      if (this.option?.options) {
        this.option?.options?.map((data) => {
          let values = Object.values(data);
          checkValueNone = values?.includes('none');
          this.dataSource = checkValueNone ? [] : this.option?.options;
        });
      }
      this.isDynamic =
        checkValueNone || this.option.isDynamic == true ? true : false;
    }
  }

  private filterStaticList() {
    if (!this.option?.options) {
      return;
    }
    // get the search keyword
    let search = this.filterFormControl.value;
    if (!search) {
      this.dataSource = this.option?.options.slice();
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the options
    this.dataSource = this.option?.options.filter((data) => {
      return data.value.toLowerCase().indexOf(search) > -1;
    });
  }

  handlePageChangeEvent(event: PageEvent): void {
    this.pageNumber = event.pageIndex;
    this.emitSearchPageChangeEvent.emit(this.pageNumber);
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
