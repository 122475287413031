import { Component, OnInit } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { MENU_DATA } from 'src/app/constants/menu.constants';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
    isDark = true;
    selectedCategory: any;
    menuData;

    constructor(private authService: MsalService,
        private router: Router,
        private overlayContainer: OverlayContainer,
        private readonly utilsService: UtilsService) {
        const category = localStorage.getItem('category');
        if (!category) {
            this.router.navigate['/main'];
        } else {
            this.selectedCategory = this.utilsService.getCategoryFromCode(category);
            const roleId = this.utilsService.getRoleId();
            this.getMenuDataBasedOnRoleAndCategory(roleId, category);
        }
    }

    getMenuDataBasedOnRoleAndCategory(role, category): void {
        this.menuData = MENU_DATA[category][role];
        this.utilsService.menuData = this.menuData;
    }

    ngOnInit(): void {
    }

    toggleTheme(): void {
        this.isDark = !this.isDark;        
    }

    logout() {
        this.authService.logout();
        this.router.navigate(["/logout"]);
    }

}
