<div class="table-container">
    <!-- search bar start -->
    <div class="table-actionbar" *ngIf="showSearch">
        <div class="search-box">
            <mat-form-field class="search-field">
                <mat-label class="search-label">Search</mat-label>
                <input matInput (keyup)="applyFilter($event)" class="search-placeholder" #input />
            </mat-form-field>
        </div>
    </div>
    <!-- search bar end -->

    <!-- table template start -->
    <table mat-table #table [dataSource]="dataSource" matTableExporter #exporter="matTableExporter" matSort class="custom-table">
        <!-- checkbox column start -->
        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox color="white" (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()">
                </mat-checkbox>
            </th>

            <td mat-cell *matCellDef="let row">
                <mat-checkbox color="white" (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
                </mat-checkbox>
            </td>
        </ng-container>
        <!-- checkbox column end -->
        
        <!-- table dynamic columns start -->
        <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ column.header }}
            </th>
            <td mat-cell *matCellDef="let row">{{ row[column.columnDef] }}</td>
        </ng-container>
        <!-- table dynamic columns end -->

        <!-- Action column template start -->
        <!-- show action column if action exist -->
        <ng-container matColumnDef="action" *ngIf="actionList?.length">
            <th mat-header-cell *matHeaderCellDef >Action</th>
            <td mat-cell *matCellDef="let row">
                <ng-container *ngFor="let action of actionList">
                    <a [class]="action.name + '-button action-button'" (click)="onTableAction(row, action.name)">
                        <i [class]="action.iconClass" [matTooltip]="action.name" [ngStyle]="{'color':action?.iconColor ? action.iconColor : '#e0e4e4'}"></i>
                    </a>
                </ng-container>
            </td>
        </ng-container>
        <!-- Action column template end -->

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <!-- table template end -->

    <!-- No Record found template start --> 
    <div *ngIf="!dataSource?.data.length || !dataSource?.filteredData.length">
        <table>
            <tr *ngIf="!dataSource?.data.length || !dataSource?.filteredData.length">
                <td class="no-record-data" [attr.colspan]="6">No Records Found!</td>
            </tr>
        </table>
    </div>
    <!-- No Record found template end --> 

    <!-- table pagination start -->
    <mat-paginator [pageSize]="defaultPageSize" aria-label="Select page" [pageSizeOptions]="paginationSize"
        showFirstLastButtons></mat-paginator>
    <!-- table pagination end -->
</div>