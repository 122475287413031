import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-navigation-container',
  templateUrl: './navigation-container.component.html',
  styleUrls: ['./navigation-container.component.scss']
})
export class NavigationContainerComponent implements OnInit {

  @ViewChild('sidenav') sidenav: MatSidenav;
  isExpanded = false;
  showSubmenu: boolean = true;
  isShowing = false;
  showSubSubMenu: boolean = false;
  isSecuredHomeShow: boolean = false;

  selectedMenu1: boolean = true;
  selectedMenu2: boolean = false;
  selectedMenu3: boolean = false;
  selectedMenu4: boolean = false;
  selectedMenu5: boolean = false;
  selectedMenu6: boolean = false;
  selectedMenu7: boolean = false;
  selectedMenu8: boolean = false;
  selectedMenu9: boolean = false;
  selectedMenu10: boolean = false;
  selectedMenu11: boolean = false;
  userRole: any;


  constructor(private router: Router,
    private service: DataService) {
    this.service.rotationData.subscribe((menuId: any) => {
      this.service.rotationDataIndex.subscribe((index: number) => {
        if (menuId != undefined) menuId.length >= 1 ? this.getMenuDetails(menuId[index].id) : this.getMenuDetails(menuId.id);
      });
    });
  }

  ngOnInit(): void {
    this.userRole = localStorage.getItem('role');
  }

  mouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  mouseleave() {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }



  deselectAllSelectedMenus() {
    this.selectedMenu1 = false;
    this.selectedMenu2 = false;
    this.selectedMenu3 = false;
    this.selectedMenu4 = false;
    this.selectedMenu5 = false;
    this.selectedMenu6 = false;
    this.selectedMenu7 = false;
    this.selectedMenu8 = false;
    this.selectedMenu9 = false;
    this.selectedMenu10 = false;
    this.selectedMenu11 = false;
  }

  getAzureMap() {
    this.deselectAllSelectedMenus();
    this.router.navigate(["/home/polar-delight"]);
  }

  getMenuDetails(menuId: number) {
    this.deselectAllSelectedMenus();
    switch (menuId) {
      case 1: {
        this.selectedMenu1 = true;
        this.router.navigate(["/home/opc-simulator"]);
        break;
      }
      case 2: {
        this.selectedMenu2 = true;
        this.router.navigate(["/home/user-mgmt"]);
        break;
      }
      case 3: {
        this.selectedMenu3 = true;
        this.router.navigate(["/home/device-management/device-list"]);
        break;
      }
      case 4: {
        this.selectedMenu4 = true;
        this.router.navigate(["/home/use-cases"]);
        break;
      }
      case 5: {
        this.selectedMenu5 = true;
        this.router.navigate(["/home/device-management"]);
        break;
      }
      case 6: {
        this.selectedMenu6 = true;
        this.router.navigate(["/home/device-management/device-twin-model"]);
        break;
      }
      case 7: {
        this.selectedMenu7 = true;
        this.router.navigate(["/home/smart-warehouse-frame"]);
        break;
      }
      case 8: {
        this.selectedMenu8 = true;
        this.router.navigate(["/home/asset-tracking"]);
        break;
      }
      case 9: {
        this.selectedMenu9 = true;
        this.router.navigate(["/home/hybrid-power-plant"]);
        break;
      }
      case 10: {
        this.selectedMenu10 = true;
        this.router.navigate(["/home/cutting-blade"]);
        break;
      }
      case 11: {
        this.selectedMenu11 = true;
        this.router.navigate(["/home/device-management/firmware-list"]);
        break;
      }
      default: {
        //statements;
        break;
      }
    }

  }


}
