import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TableAction, TableColumn } from 'src/app/common/models/index';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() columns: TableColumn[] = [];
  @Input() tableData: any[] = [];
  @Input() showPagination = true;
  @Input() isSortable = false;
  @Input() showSearch = true;
  @Input() showSelectionBox = false;
  @Input() tableColumns: TableColumn[] = [];
  @Input() paginationSize: number[] = [5, 10, 15];
  @Input() defaultPageSize = 2;
  @Input() actionList: TableAction[] = [];

  @Output() executedAction = new EventEmitter<any>();

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);
  displayedColumns: string[] = [];
  value: string;

  constructor() {}

  ngOnInit() {
    // set checkbox column if showSelectionBox is true
    this.showSelectionBox
      ? this.displayedColumns.push('select')
      : this.displayedColumns;

    // set table columns
    this.displayedColumns = this.displayedColumns.concat(
      this.columns.map((x) => x.columnDef)
    );

    // add action column if actions exist
    this.actionList?.length
      ? this.displayedColumns.push('action')
      : this.displayedColumns;

    // set table data
    this.dataSource = new MatTableDataSource<any>(this.tableData);

    // set pagination
    this.dataSource.paginator = this.paginator;
  }

  ngOnChanges(){
    this.dataSource = new MatTableDataSource(this.tableData);
  }

  /**
   * onTableAction emits the event to parent component with the rowData and action name
   * @param rowData : table row data
   * @param action : action like delete ,edit performed by the user
   */
  onTableAction(rowData: any, action: string): void {
    const clickedRow = {
      rowData: rowData,
      actionPerformed: action,
    };
    this.executedAction.emit(clickedRow);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  /** column sorting is performed here */
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  /** applyFilter searches the entered input value */
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
