import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { HomeComponent } from './features/home/components/home/home.component';
import { MainComponent } from './features/main/components/main/main.component';

const routes: Routes = [

  {
    path: '',
    loadChildren: () => import('./features/basic/basic.module').then(m => m.BasicModule)
  },
  {
    path: 'main',
    component: MainComponent
  },
  {
    path: 'home',
    component: HomeComponent,
    loadChildren: () => import('./features/home/home.module').then(m => m.HomeModule),
    canActivate: [
      MsalGuard
    ]
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      useHash: false,
      // enableTracing: true,
      preloadingStrategy: PreloadAllModules
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
