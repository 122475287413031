import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UtilsService } from 'src/app/services/utils/utils.service';
import { CATEGORY_DATA } from 'src/app/constants/category-data';

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
    tilesData = CATEGORY_DATA;
    selectedTile: any;
    userRole;    

    constructor(private readonly router: Router,
        private readonly utilsService: UtilsService) {
        this.userRole = this.utilsService.getRoleId();
        this.updateTiles();
    }

    ngOnInit(): void {
    }

    onTileSelect(tile): void {
        localStorage.setItem('category', tile.code);
        this.utilsService.category = tile;
        this.selectedTile = tile;
        this.router.navigate(['/home']);
    }

    updateTiles(): void {

    }



}
