<div class="ey-h-100 ey-pr main-offering-container">
    <div>
        <app-header [title]="selectedTile?.title"></app-header>
    </div>
    <div class="row content">
        <div class="col-md-12 col-lg-12 row">
            <div class="container col-sm-6 col-md-6 col-lg-6 col-xl-6" *ngFor="let tile of tilesData" >
                <mat-card class="mat-elevation-z4 bgColor">
                    <img class="img" mat-card-image [src]="tile.src" alt="Photo of {{ tile.label }}" />
                    <mat-card-content class="tile-body">
                        <div class="tile-title">{{ tile.label }}</div>
                        <div class="tile-description">{{ tile.description }}</div>
                    </mat-card-content>
                    <mat-card-actions *ngIf="tile.status=='ready'" class='ready' (click)="onTileSelect(tile)">
                        View Offerings
                    </mat-card-actions>
                    <mat-card-actions *ngIf="tile.status=='in-progress'" class='in'>
                        Coming Soon
                    </mat-card-actions>
                </mat-card>
            </div>
        </div>
    </div>
</div>