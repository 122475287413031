import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AppEventType } from 'src/app/enums/app-event.enum';

//This class is used for broadcasting the App event 
export class AppEvent<T> {
  /**
   * initialize the app event
   * @param type enum containing the types of app events
   * @param payload it is the data sent with the event
   */  
   constructor(public type: AppEventType, public payload: T ) {}
}

//This service is used for inter component communication
@Injectable({
  providedIn: 'root',
})
export class MessageService {
  //Event Handler broadcasts and subscribes to the events
  private eventHandler = new Subject<AppEvent<any>>();
  
  /**
   * This method is subscribed to get data corresponding to the events
   * @param eventType AppEventType
   * @returns an Observable of type AppEvent
   */
  on(eventType: AppEventType): Observable<AppEvent<any>> {
    return this.eventHandler.pipe(filter((event) => event.type === eventType));
  }
    
  /**
   * This method is used to broadcast data associated with any event
   * @param event AppEvent contains event and payload
   */
  broadcast<T>(event: AppEvent<T>): void {
    this.eventHandler.next(event);
  }
}
