import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { MsalRedirectComponent } from '@azure/msal-angular';

import { HeaderComponent } from './features/home/components/header/header.component';
import { HomeComponent } from './features/home/components/home/home.component';
import { NavigationContainerComponent } from './features/home/components/navigation-container/navigation-container.component';
import { NavComponent } from './common/components/nav/nav.component';
import { MainComponent } from './features/main/components/main/main.component';
import { ApplicationInsightsService } from './services/application-insights.service';
import { ErrorHandler } from '@angular/core';
import { MentionModule } from './lib/angular-mentions';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    NavigationContainerComponent,
    NavComponent,
    MainComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    CoreModule,
    MentionModule
  ],
  providers: [
    ApplicationInsightsService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
