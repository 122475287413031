<div>
  <form [formGroup]="form" class="row">
    <div *ngFor="let option of dynamicFormOptions" class="col-lg-4 col-md-6 col-12 ey-mb-15">
      <app-dynamic-form-options class="ey-w-100" [showErrors]="showErrors" [option]="option"
        [form]="form" (emitSearchedInput)="getSearchedInput($event)"
        (emitSearchPageChangeValue)="handleSearchPageChange($event)"
      ></app-dynamic-form-options>
    </div>
  </form>
  <div class="ey-sb-ac create-asset-btn-container">
    <button type="submit" mat-button class="btn-theme" (click)="onSubmit()">
      <mat-icon>create</mat-icon>
      Create Asset
    </button>
  </div>
</div>
