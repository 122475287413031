import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatDialogModule } from '@angular/material/dialog';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTreeModule } from '@angular/material/tree';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatTableExporterModule } from 'mat-table-exporter';

import { CardComponent } from '../common/components/card/card.component';
import { TableComponent } from '../common/components/table/table.component';
import { DynamicIframeLoaderComponent } from '../common/components/dynamic-iframe-loader/dynamic-iframe-loader.component';
import { BreadcrumbComponent } from '../common/components/breadcrumb/breadcrumb.component';
import { DynamicFormComponent } from '../common/components/dynamic-form/dynamic-form.component';
import { DynamicFormOptionsComponent } from '../common/components/dynamic-form-options/dynamic-form-options.component';

import { RolePipe } from './components/customPipe/role.pipe';

import { ToastrModule, ToastrService } from 'ngx-toastr';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { THIRD_PARTY_CONFIGURATIONS } from '../configurations/third-party-config';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';

import { SafePipe } from './pipe/safe.pipe';
import { DynamicSearchSelectComponent } from '../common/components/dynamic-field/dynamic-search-select/dynamic-search-select.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatChipsModule } from '@angular/material/chips';
import { DynamicTableComponent } from './components/dynamic-table/dynamic-table.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatDividerModule } from '@angular/material/divider';

const materialModules = [
  MatGridListModule,
  MatToolbarModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatFormFieldModule,
  MatInputModule,
  MatListModule,
  MatCardModule,
  MatNativeDateModule,
  MatDatepickerModule,
  MatSidenavModule,
  MatTableExporterModule,
  MatTabsModule,
  MatTableModule,
  MatSortModule,
  MatSelectModule,
  MatDialogModule,
  MatPaginatorModule,
  MatTreeModule,
  MatCheckboxModule,
  MatIconModule,
  MatSlideToggleModule,
  NgxMatTimepickerModule,
  MatTooltipModule,
  MatMenuModule,
  MatExpansionModule,
  MatChipsModule,
  MatRadioModule,
  MatDividerModule
];

const reusableComponents = [
  BreadcrumbComponent,
  CardComponent,
  TableComponent,
  DynamicIframeLoaderComponent,
  DynamicFormComponent,
  DynamicFormOptionsComponent,
  DynamicSearchSelectComponent,
  DynamicTableComponent,
];

@NgModule({
  declarations: [...reusableComponents, RolePipe, SafePipe],
  imports: [
    CommonModule,
    ...materialModules,
    FormsModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    ToastrModule.forRoot(THIRD_PARTY_CONFIGURATIONS.TOASTR_CONFIG),
    NgCircleProgressModule.forRoot(
      THIRD_PARTY_CONFIGURATIONS.NG_CIRCLE_PROGRESS_CONFIG
    ),
    ModalModule.forRoot(),
    NgScrollbarModule,
  ],
  exports: [
    ...materialModules,
    ...reusableComponents,
    NgxMatSelectSearchModule,
    NgCircleProgressModule,
    ToastrModule,
    ModalModule,
    RolePipe,
    NgScrollbarModule,
  ],
  providers: [ToastrService, BsModalService, DatePipe, RolePipe, SafePipe],
})
export class SharedModule {}
