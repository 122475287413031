import { Injectable } from '@angular/core';
import { environment } from '@env';
import * as signalR from '@microsoft/signalr';
import { ToasterWrapperService } from '../services/toaster-wrapper.service';
import { AuthService } from './auth/auth.service';


@Injectable({
  providedIn: 'root'
})

export class SignalRService {
  signalRUrl = environment.signalRApiUrl;

  idToken: string;
  hubConnection: signalR.HubConnection;

  constructor(private toast: ToasterWrapperService, private authService: AuthService) {
    this.idToken = this.authService.token;
  }

  startConnection() {

    const headersignalR = {
      serverlessauthorization: `Bearer ${this.idToken}`,
    };

    this.hubConnection = new signalR.HubConnectionBuilder()      
      .withUrl(this.signalRUrl + 'api', { headers: headersignalR })
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Information)
      .build();

    this.hubConnection.start().then(function () {
        console.log('Signal R Connected!');    // added for testing
    }).catch(function (err) {
        console.log('error while connecting to signal R',err.toString());
    });
  }

}
