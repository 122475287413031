<div>
  <mat-sidenav-container class="menu-container" autosize color="primary">
    <mat-sidenav #sidenav color="primary" class="menu-sidenav" mode="side" opened="true" (mouseenter)="mouseenter()"
      (mouseleave)="mouseleave()">
      <mat-nav-list>
        <!-- <mat-list-item (click)="getMenuDetails(1)" class="parent" [ngClass]="{'selected-menu' : selectedMenu1}">
          <img src="assets/images/coffee_maker.png" alt="plantView" class="home-icon" />
          <span class="full-width" *ngIf="isExpanded || isShowing">Polar Delight</span>
        </mat-list-item> -->
        <!-- <mat-list-item (click)="getMenuDetails(2)" class="parent" [ngClass]="{'selected-menu' : selectedMenu2}">
          <img src="assets/images/precision_manufacturing.png" alt="plantView" class="home-icon" />
          <span class="full-width" *ngIf="isExpanded || isShowing">CNC</span>
        </mat-list-item> -->
        <mat-list-item class="parent">
          <i class="home-icon fa fa-desktop mr-1" style="font-size:18px;" alt="plantView" aria-hidden="true"></i>
          <span class="full-width" *ngIf="isExpanded || isShowing">Device Management</span>
          <mat-icon color="primary" class="menu-button" [ngClass]="{'rotated' : showSubmenu}"
            *ngIf="isExpanded || isShowing">
          </mat-icon>
        </mat-list-item>
        <div class="submenu" [ngClass]="{'expanded' : showSubmenu}" *ngIf="isShowing || isExpanded">
          <a (click)="getMenuDetails(5)" class="sideMenuItems" mat-list-item><i
              class="pr-2 fas fa-newspaper"></i>Summary</a>
          <a (click)="getMenuDetails(3)" class="sideMenuItems" mat-list-item><i class="pr-2 fas fa-list"></i>Device
            List</a>
          <a (click)="getMenuDetails(11)" class="sideMenuItems" mat-list-item><i class="pr-2 fas fa-list"></i>FOTA</a>
        </div>
        <mat-list-item (click)="getMenuDetails(1)" class="parent" [ngClass]="{'selected-menu' : selectedMenu1}">
          <!-- <img src="assets/images/realTime.png" alt="plantView" class="home-icon" /> -->
          <mat-icon>grain</mat-icon>
          <span class="full-width" *ngIf="isExpanded || isShowing">Device Simulator</span>
        </mat-list-item>
        <mat-list-item (click)="getMenuDetails(2)" class="parent" [ngClass]="{'selected-menu' : selectedMenu2}">
          <i class="home-icon fa fa-user mr-1" style="font-size:19px;" alt="plantView" aria-hidden="true"></i>
          <span class="full-width" *ngIf="isExpanded || isShowing">User Management</span>
        </mat-list-item>
        <!-- <mat-list-item (click)="getMenuDetails(4)" class="parent" [ngClass]="{'selected-menu' : selectedMenu4}">
          <i class="home-icon fa fa-cubes mr-1" style="font-size:23px;" alt="plantView" aria-hidden="true"></i>
          <span class="full-width" *ngIf="isExpanded || isShowing">Use Cases</span>
        </mat-list-item> -->
        <mat-list-item class="parent">
          <i class="home-icon fa fa-cubes mr-1" style="font-size:19px;" alt="plantView" aria-hidden="true"></i>
          <span class="full-width" *ngIf="isExpanded || isShowing">Use Cases</span>
          <mat-icon color="primary" class="menu-button" [ngClass]="{'rotated' : showSubmenu}"
            *ngIf="isExpanded || isShowing">
          </mat-icon>
        </mat-list-item>
        <div class="submenu" [ngClass]="{'expanded' : showSubmenu}" *ngIf="isShowing || isExpanded">
          <a (click)="getMenuDetails(7)" class="sideMenuItems" mat-list-item><i class="pr-2 fas fa-warehouse"></i>Smart
            Warehouse</a>
          <a (click)="getMenuDetails(8)" class="sideMenuItems" mat-list-item><i class="pr-2 fas fa-road"></i>Asset
            Tracking</a>
          <a (click)="getMenuDetails(9)" class="sideMenuItems" mat-list-item><i
              class="pr-2 fas fa-solar-panel"></i>Hybrid Power Plant</a>
          <a (click)="getMenuDetails(10)" class="sideMenuItems" mat-list-item><i
              class="pr-2 fas fa-radiation"></i>Cutting Blade</a>
          <a class="sideMenuItems" mat-list-item><i class="pr-2 fas fa-binoculars"></i>Package Monitoring</a>
        </div>
        <!-- <mat-list-item (click)="getMenuDetails(4)" class="parent" [ngClass]="{'selected-menu' : selectedMenu4}" *ngIf='"opcSim" | role:userRole'>
          <i class="home-icon fa fa-user mr-1" style="font-size:23px;" alt="plantView" aria-hidden="true"></i>
          <span class="full-width" *ngIf="isExpanded || isShowing">Electric Vehicle</span>
        </mat-list-item> -->
      </mat-nav-list>

    </mat-sidenav>
    <div class="menu-sidenav-content">
      <router-outlet></router-outlet>
    </div>

  </mat-sidenav-container>
</div>