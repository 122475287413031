import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  startWith,
  takeUntil,
} from 'rxjs/operators';
import { DYNAMIC_FORM_CONTROL_OPTIONS } from 'src/app/enums/dynamic-form-control-options';
import { OptionBase } from 'src/app/models/option-base';
@Component({
  selector: 'app-dynamic-form-options',
  templateUrl: './dynamic-form-options.component.html',
  styleUrls: ['./dynamic-form-options.component.scss'],
})
export class DynamicFormOptionsComponent implements OnInit, OnDestroy {
  controlTypesEnum = DYNAMIC_FORM_CONTROL_OPTIONS;
  @Input() option!: OptionBase<string>;
  @Input() showErrors!: boolean;
  @Input() form!: FormGroup;
  searchMultiSelectCtrl: FormControl = new FormControl();
  searchedInput: string;
  searchSelectPageNumber: number = 0;
  protected _onDestroy = new Subject<void>();
  @ViewChild('multiSelect', { static: true }) multiSelect: MatSelect;
  get isInValid() {
    return (
      (this.form?.controls?.[this.option?.propertyName].touched ||
        this.showErrors) &&
      !this.form?.controls?.[this.option?.propertyName]?.valid
    );
  }

  @Output() emitSearchedInput = new EventEmitter<string>();
  @Output() emitSearchPageChangeValue = new EventEmitter<number>();

  getSearchedInput(input: string) {
    this.emitSearchedInput.emit(input);
  }

  handleSearchPageChangeEvent(searchPageNumber: number) {
    this.emitSearchPageChangeValue.emit(searchPageNumber);
  }

  constructor() {}
  multiSelectDataSource: any;
  ngOnInit(): void {
    this.multiSelectDataSource = this.option?.options;
    this.listenToSearchMultiSelectCtrlChanges();
  }

  private listenToSearchMultiSelectCtrlChanges() {
    this.searchMultiSelectCtrl.valueChanges
      .pipe(
        startWith(''),
        takeUntil(this._onDestroy),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe((data) => {
        this.filterMultiSelectStaticData(data);
      });
  }

  private filterMultiSelectStaticData(searchedValue: string) {
    if (!this.option?.options) {
      return;
    }
    if (!searchedValue) {
      this.multiSelectDataSource = this.option?.options.slice();
      return;
    } else {
      searchedValue = searchedValue.toLowerCase();
    }
    // filter the options
    this.multiSelectDataSource = this.option?.options.filter((data) => {
      return data.value.toLowerCase().indexOf(searchedValue) > -1;
    });
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
