<mat-sidenav-container class="ey-side-nav" autosize>
  <mat-sidenav color="primary" mode="side" opened (mouseenter)="openSidebar=true" (mouseleave)="openSidebar=false">
    <mat-nav-list>
      <div class="sidebar" [class.sidebar-close]="!openSidebar">
        <div class="nav-links" id="nav-links">
          <div class="menu-list" *ngFor="let item of menuList" #itemEl routerLinkActive="active">            
            <div *ngIf="item.children.length == 0" class="dropdown-title">
              <a [routerLink]="[item.routerLink]">
                <mat-icon class="menu-icon">{{item.icon}}</mat-icon>
                <span *ngIf="openSidebar" class="link_name">{{item.label}}</span>
              </a>
            </div>
            <div *ngIf="item.children.length > 0" class="dropdown-title" (click)="showSubmenu(itemEl)">
              <a>
                <mat-icon class="menu-icon">{{item.icon}}</mat-icon>
                <span *ngIf="openSidebar" class="link_name">{{item.label}}</span>
              </a>
              <mat-icon *ngIf="openSidebar" class="dropdown-arrow">keyboard_arrow_down</mat-icon>
            </div>
            <div class="sub-menu" [class.blank]="item.children.length == 0">
              <div><a class="link_name">{{item.label}}</a></div>
              <div *ngFor="let item_sub of item.children" routerLinkActive="active" #leafEl>
                <div *ngIf="item_sub.children.length == 0">
                  <a class="item_sub_label" [routerLink]="[item_sub.routerLink]">{{item_sub.label}}</a>
                </div>
                <div *ngIf="item_sub.children.length > 0" class="sub-dropdown-title" (click)="showLeafMenu(leafEl)">
                  <div class="sub-dropdown-title-content">
                    <a *ngIf="openSidebar" class="sub-link-name">{{item_sub.label}}</a>
                    <mat-icon *ngIf="openSidebar" class="sub-dropdown-arrow">keyboard_arrow_down</mat-icon>
                  </div>
                </div>
                <div class="leaf-sub-menu" [class.blank]="item_sub.children.length == 0">
                  <div class="leaf-sub-menu-content" *ngFor="let leaf_menu of item_sub.children" routerLinkActive="active">
                    <div *ngIf="leaf_menu.children.length == 0">
                      <a [routerLink]="[leaf_menu.routerLink]">{{leaf_menu.label}}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content class="side-content">
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>